import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import useStore from "@/services/store";
import api from "@/services/api";

const Signin = () => {
  const [seePassword, setSeePassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const { setUser } = useStore();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { ok, data } = await api.post("/user/signin", values);
      if (!ok) return toast.error("Une erreur est survenue");

      setUser(data.user);
      api.setToken(data.token);
      toast.success("Vous êtes connecté !");
    } catch (error) {
      toast.error("Une erreur est survenue");
      console.error(error);
    }
  };

  return (
    <div>
      <h1 className="text-center text-4xl font-bold text-gray-800 mb-12">Se connecter</h1>

      <form className="w-full space-y-4" onSubmit={handleSubmit}>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Email
          </label>
          <input type="email" name="email" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label className="text-base font-medium" htmlFor="password">
              Mot de passe
            </label>
            <Link to="/forgot-password" className="text-primary leading-6 text-xs hover:underline">
              Mot de passe oublié ?
            </Link>
          </div>
          <div className="relative">
            <input
              type={seePassword ? "text" : "password"}
              name="password"
              className="input"
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              placeholder={"Enter your password here"}
            />
            <div className="absolute top-1/2 -translate-y-1/2 right-4">
              {seePassword ? (
                <EyeSlashIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(false)} />
              ) : (
                <EyeIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(true)} />
              )}
            </div>
          </div>
        </div>
        <button disabled={!values.email || !values.password} type="submit" className="primary-button w-full !mt-10">
          Se connecter
        </button>
      </form>
      <div className="flex items-center gap-x-1 mt-4 text-left mr-auto">
        <p className="leading-6">Vous n'avez pas de compte ?</p>
        <Link to="/signup" className="font-medium text-primary">
          S'inscrire
        </Link>
      </div>
    </div>
  );
};

export default Signin;

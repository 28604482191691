import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "@/services/api";
import Loader from "@/components/Loader";

const PICTURES = [
  "https://th.bing.com/th/id/R.891a1be588a0303ba8b24c1570a01bae?rik=h932gdTVK1psTg&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.O-kSeGcdCsgSYSKEsqHVdwHaE7?w=1280&h=853&rs=1&pid=ImgDetMain",
  "https://votrepolice.ch/wp-content/uploads/2020/05/entreprises-hd.png",
  "https://www.megahubhk.com/wp-content/uploads/2015/07/Final-Company-Background-Image.jpg",
  "https://www.digitalentrepreneur.fr/wp-content/uploads/2020/05/blog-entrepreneur.jpg",
  "https://www.novalum.fr/wp-content/uploads/2020/09/entreprise-corporate-1.jpg",
  "https://alliance-sciences-societe.fr/wp-content/uploads/2019/10/lorganisation-entreprise-optimiser-activites-comment.jpg",
  "https://th.bing.com/th/id/R.9f33af7b385e6dbca0ae3107c6762dd1?rik=A0g2SBfPXtv8Ag&riu=http%3a%2f%2fwww.hsm-services.fr%2fwp-content%2fuploads%2f2019%2f08%2fmanagement-indispensable-entreprise.jpg&ehk=4BneOG2CFGqM1UBIQ1ofPd1QxEx%2btadmjH3dqrajzoc%3d&risl=&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/R.9cb56bba1d502461114b7a1fc591a639?rik=lBuClpiOga3VVw&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.KJJcNR_SJt3Gw3YkSZUj3AHaE8?rs=1&pid=ImgDetMain",
  "https://th.bing.com/th/id/R.6d3fce6fb0c0898a96ce086887b0e194?rik=SUTaab9Pccyr3Q&pid=ImgRaw&r=0",
  "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/V1xq1AADx/videoblocks-group-of-business-people-meeting-around-table-20s-4k_sncaqskgw_thumbnail-1080_01.png",
  "https://www.singaporetranscription.com/wp-content/uploads/2017/01/meeting.jpg",
  "https://c.stocksy.com/a/5ka300/z9/856225.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-1.jpg",
  "https://wallpapercave.com/wp/wp8656243.png",
  "https://wallpapercave.com/wp/wp8656441.jpg",
  "https://www.jcount.com/wp-content/uploads/2015/12/business-meeting-to-be-productive.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-14.jpg",
  "https://1000decos.fr/wp-content/uploads/2021/03/paysagiste2.jpg",
  "https://th.bing.com/th/id/OIP.bmQ1Z83MU9PQTkvL2JAbgAHaE8?rs=1&pid=ImgDetMain",
];

const ANNONCE_TYPE = {
  cession: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Cession</p>,
  levee: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Levée de fonds</p>,
};

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    annonce: true,
    annonce_status: "ACCEPTED",
    annonce_type: searchParams.get("type") || "",
    annonce_title: searchParams.get("title") || "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post(`/company/search`, query);
        if (res.ok) {
          setData(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (error) {
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };

    fetchData();
  }, [query]);

  return (
    <section className="p-8 space-y-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Annonces de cessions et de levées de fonds</h1>
        <div className="space-y-2">
          <p className="text-base text-gray-500">Explorez des opportunités d'achat et de vente d'entreprises</p>
          <input
            type="text"
            placeholder="Rechercher une annonce..."
            className="mt-1 block w-full px-3 py-2 border border-secondary bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={query.annonce_title}
            onChange={(event) => setQuery({ ...query, annonce_title: event.target.value })}
          />
        </div>

        <select
          className="select w-60 mt-5 cursor-pointer text-black opacity-60 focus:outline-none border border-secondary"
          value={query.annonce_type}
          onChange={(event) => {
            const newType = event.target.value;
            setSearchParams({ type: newType });
            setQuery((prev) => ({ ...prev, annonce_type: newType }));
          }}
        >
          <option value="">Type d'annonce</option>
          {Object.keys(ANNONCE_TYPE).map((key) => (
            <option key={key} value={key}>
              {ANNONCE_TYPE[key]}
            </option>
          ))}
        </select>
      </header>

      {loading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-4 gap-4 mt-10">
            {data.length > 0 ? data.map((e, index) => <Card key={index} data={e} />) : <p className="text-center mt-10">Aucune annonce trouvée</p>}
          </div>
        </>
      )}
    </section>
  );
};

const Card = ({ data }) => {
  const randomIndex = Math.floor(Math.random() * PICTURES.length);
  const randomPictureSrc = PICTURES[randomIndex];
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(`/annonce/${data._id}?interested=true`);
  };

  return (
    <Link to={`/annonce/${data._id}`} className="flex flex-col gap-2 justify-start border border-secondary rounded-xl p-6 space-y-4 hover:border-primary">
      <img alt={data.annonce_title} src={data.annonce_image ? data.annonce_image : randomPictureSrc} className="w-full h-40 object-cover rounded-lg" />
      <div className="flex-1 flex-col justify-start mt-2">
        <div className="flex mb-2">{ANNONCE_TYPE[data.annonce_type]}</div>
        <h1 className="text-xl font-medium text-black mb-4">{data.annonce_title}</h1>
        {(data.annonce_financing_needs || data.annonce_selling_price) !== undefined && (
          <p className="text-sm text-gray-400 h-1/6">
            {/* Montant souhaité : <span>{(data.annonce_financing_needs || data.annonce_selling_price).toLocaleString("fr", { currency: "EUR", style: "currency" })}</span> */}
          </p>
        )}
      </div>
      {data.annonce_type === "cession" && (
        <button
          onClick={handleClick}
          className="flex justify-center text-center w-full hover:bg-primary text-primary font-semibold hover:text-white mt-3 py-2 px-3 text-sm border border-primary hover:border-transparent rounded disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
        >
          Demander une mise en relation
        </button>
      )}
    </Link>
  );
};

export default List;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { IoWalletOutline, IoChatbubbleOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { RiArrowDownSLine, RiExchangeDollarLine, RiFundsLine, RiHandCoinLine, RiHome6Line } from "react-icons/ri";
import { PiChartPieSlice, PiUserList } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import { TbShoppingBagSearch } from "react-icons/tb";
import { SlGraph } from "react-icons/sl";

import LogoPNG from "@/assets/img/logo_text_vertical.png";

const Navbar = () => {
  const [selected, setSelected] = useState();

  return (
    <div className="h-screen bg-white border-r border-secondary shadow">
      <div className="flex flex-col gap-1 py-4 h-full space-y-1">
        <div className="relative mx-4">
          <img className="mb-2 w-24 mx-auto" alt="logo" src={LogoPNG} />
          <span className="absolute right-0 bottom-0 text-primary font-bold text-sm rounded-full mx-4 p-1">beta</span>
        </div>

        <Link to="/home" className="flex items-center gap-3 mx-4 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("home")}>
          <div className={`${selected === "home" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
            <RiHome6Line className="h-5 w-5" />
          </div>
          <p className={`text-sm ${selected === "home" ? "font-medium" : ""}`}>Accueil</p>
        </Link>

        <div className="h-full flex-1 flex flex-col px-4 mb-2">
          <div>
            <div className="flex justify-between items-center cursor-pointer p-2 rounded-md bg-gray-50 hover:bg-gray-100">
              <p className="text-sm font-medium">Services</p>
              <RiArrowDownSLine className="h-5 w-5" />
            </div>
            <div className="mt-2 ml-2 pl-1 border-l-2">
              <Link to="/valuation/new" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("new-valuation")}>
                <div className={`${selected === "new-valuation" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <PiChartPieSlice className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "new-valuation" ? "font-medium" : ""}`}>Valorisation</p>
              </Link>
              <Link to="/notation" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("notation")}>
                <div className={`${selected === "notation" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <SlGraph className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "notation" ? "font-medium" : ""}`}>Notation</p>
              </Link>
              <Link to="/levee/new" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("levee")}>
                <div className={`${selected === "levee" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <RiFundsLine className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "levee" ? "font-medium" : ""}`}>Levée de fonds</p>
              </Link>
              <Link to="/cession/new" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("cession")}>
                <div className={`${selected === "cession" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <RiExchangeDollarLine className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "cession" ? "font-medium" : ""}`}>Cession</p>
              </Link>
              <Link to="/buyer-service/new" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("buyer-service")}>
                <div className={`${selected === "buyer-service" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <RiHandCoinLine className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "buyer-service" ? "font-medium" : ""}`}>Acquisition</p>
              </Link>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center cursor-pointer p-2 rounded-md bg-gray-50 hover:bg-gray-100">
              <p className="text-sm font-medium">Opportunités</p>
              <RiArrowDownSLine className="h-5 w-5" />
            </div>
            <div className="mt-2 ml-2 pl-1 border-l-2">
              <Link to="/annonce" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("annonce")}>
                <div className={`${selected === "annonce" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <IoIosSearch className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "annonce" ? "font-medium" : ""}`}>Annonces</p>
              </Link>
            </div>
          </div>
          <div>
            <div className="flex justify-between items-center cursor-pointer p-2 rounded-md bg-gray-50 hover:bg-gray-100">
              <p className="text-sm font-medium">Mes Activités</p>
              <RiArrowDownSLine className="h-5 w-5" />
            </div>
            <div className="mt-2 ml-2 pl-1 border-l-2">
              <Link to="/my-service" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("services")}>
                <div className={`${selected === "services" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <PiUserList className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "services" ? "font-medium" : ""}`}>Mes Services</p>
              </Link>
              <Link to="/my-annonce" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("my-annonce")}>
                <div className={`${selected === "my-annonce" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <LuListTodo className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "my-annonce" ? "font-medium" : ""}`}>Mes annonces</p>
              </Link>
              <Link to="/discussion" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("discussion")}>
                <div className={`${selected === "discussion" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <IoChatbubbleOutline className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "discussion" ? "font-medium" : ""}`}>Discussions</p>
              </Link>
            </div>
          </div>

          <div className="mt-auto mb-2">
            <div className="flex justify-between items-center cursor-pointer p-2 rounded-md bg-gray-50 hover:bg-gray-100">
              <p className="text-sm font-medium">Annuaire</p>
              <RiArrowDownSLine className="h-5 w-5" />
            </div>
            <div className="mt-2 ml-2 pl-1 border-l-2">
              <Link to="/investisseurs" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("investisseurs")}>
                <div className={`${selected === "investisseurs" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <IoWalletOutline className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "investisseurs" ? "font-medium" : ""}`}>Investisseurs</p>
              </Link>
              <Link to="/buyers" className="flex items-center gap-3 p-1 rounded-md hover:bg-gray-100" onClick={() => setSelected("buyers")}>
                <div className={`${selected === "buyers" ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-8 h-7`}>
                  <TbShoppingBagSearch className="h-5 w-5" />
                </div>
                <p className={`text-sm ${selected === "buyers" ? "font-medium" : ""}`}>Acquéreurs</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
